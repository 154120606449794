<template>
  <div>

    <!-- Welcome -->
    <v-row
        class="welcome flex-row mx-0"
        :class="'welcomeBg' + welcomeBgIndex"
        align="center"
        justify="center"
        aria-orientation="vertical"
    >
      <div class="overlay"></div>
      <h2
          class="px-10"
      >
        <span>
          <v-icon class="white--text mr-2" style="margin-top: -5px">fas fa-globe</v-icon>
          {{ $vuetify.lang.t('$vuetify.home.welcome.org') }}
        </span>
        <br>
        {{ $vuetify.lang.t('$vuetify.home.welcome.slogan') }}
      </h2>
    </v-row>

    <v-container>
      <!-- Who we are -->
      <v-row
          class="text-center mt-md-11 mb-5"
          id="about-us"
      >

        <v-col
            cols="12"
            class="mb-md-5"
        >
          <h2 class="text-uppercase">
            {{ $vuetify.lang.t('$vuetify.home.whoWeAre.title') }}
          </h2>
        </v-col>



        <v-col
            md="6"
            cols="12"
            v-for="index in $vuetify.lang.raw('$vuetify.home.whoWeAre.points').keys()"
            :key="'problem-' + index"
        >
          <v-card
              class="issue-point elevation-0 pt-6 pb-15"
          >
            <img
                :src="require('@/assets/' + $vuetify.lang.raw('$vuetify.home.whoWeAre.points.' + index + '.image'))"
                :alt="'problem-' + index + '-image'"
            >
            <h2 class="primary--text mt-2 mb-4">
              {{ $vuetify.lang.t('$vuetify.home.whoWeAre.points.' + index + '.title') }}
            </h2>
            <p
                class="text-left mb-2"
                style="white-space: pre-line"
                v-for="pIndex in $vuetify.lang.raw('$vuetify.home.whoWeAre.points.' + index + '.desc').keys()"
                :key="'problem' + index + '-desc-p-' + pIndex"
            >
              <v-icon
                  x-small
                  color="primary"
                  class="mr-1"
                  v-if="$vuetify.lang.t('$vuetify.home.whoWeAre.points.' + index + '.desc.' + pIndex).startsWith(' ')"
              >fas fa-crosshairs
              </v-icon>
              {{ $vuetify.lang.t('$vuetify.home.whoWeAre.points.' + index + '.desc.' + pIndex) }}
            </p>
          </v-card>
        </v-col>



      </v-row>
    </v-container>

  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    welcomeBgIndex: 0,
  }),
  created() {
    this.$vuetify.lang.locales.zhHans.home = {
      welcome: {
        org: "南洋环球资本",
        slogan: "信任构筑未来"
      },
      whoWeAre: {
        title: "企业介绍",
        points: [
          {
            image: "global.svg",
            title: "关于我们",
            desc: [
                "南洋环球资本（VCC-可变动资本公司）是一家在新加坡金融管理局（MAS）注册的专注于全球业务的战略投资公司。它凭借经验丰富的专业管理团队以及海内外深厚资源，为公司和合作伙伴实现高效快速的资源整合，助推业务发展。",
            ]
          },
          {
            image: "ship.svg",
            title: "企业价值",
            desc: [
              " 我们提倡诚信立足，创新致远，共创双赢。对客户的事务，始终秉承公平、诚实、透明、务实创新的服务原则。 \n",
              " 我们致力于为客户打造量身定制的投资体验，以最高效的方式满足客户需求。 \n",
              " 我们坚信，我们将站在市场发展趋势的最前沿。 \n",
            ]
          }
        ],
      },
    }
    this.$vuetify.lang.locales.en.home = {
      welcome: {
        org: "Nanyang Global Capital VCC",
        slogan: "Building Trust Building Future"
      },
      whoWeAre: {
        title: "Who We Are",
        points: [
          {
            image: "global.svg",
            title: "About Us",
            desc: [
              "Nanyang Global Capital VCC is a MAS-registered strategic investment company with a global focus. With professional teams and assets placed in multiple regions, we are able to connect various resources and achieve efficient and rapid business integration for ourselves and our partners. ",
            ]
          },
          {
            image: "ship.svg",
            title: "Our Guiding Principles",
            desc: [
              " Our relationships are built on trust and flexibility. We are fair, honest, transparent, flexible, and pragmatic to the affairs of our clientele. \n",
              " We are focused on delivering an investment experience that meets the goals of our client in the most effective manner. \n",
              " We believe in being in the forefront of the market trends. \n",
            ]
          }
        ],
      },
    }
  },
  mounted() {
    if(this.hash) {
      this.$vuetify.goTo(this.hash)
    }
    setInterval(() => {
      // only change bg when page in on display,
      // otherwise page may blink a bit to complete all changes during the missing display time
      if (document.visibilityState !== 'visible') return
      this.welcomeBgIndex = (this.welcomeBgIndex + 1) % 2
    }, 13000);
  },
  computed: {
    hash: function () {
      return this.$route.hash
    }
  },
  watch: {
    hash: function (n) {
      if (n) {
        this.$vuetify.goTo(n)
      }
    }
  },
}
</script>

<style lang="less" scoped>
.welcome {
  height: 720px;
  transition: background-image 0.8s ease-in-out;
  background-size: cover;

  .overlay {
    position: absolute;
    background-image: linear-gradient(rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0.01));
    width: 100%;
    height: 720px;
    z-index: 0;
  }

  h2 {
    color: #ffffff;
    font-size: 3.2em;
    font-weight: bold;
    z-index: 1;
    margin-bottom: 2.23em;

    span {
      font-size: 0.45em;
    }
  }
}

.welcomeBg0 {
  background-image: url("~@/assets/welcome0.jpg");
  //background-position: 50% 18%;
}

.welcomeBg1 {
  background-image: url("~@/assets/welcome1.jpg");
  //background-position: 50% 18%;
}

.issue-point {
  padding: 13px 28px 26px 28px;
  border: 3px solid #fafafa;
  height: 100%;

  img {
    margin-bottom: -22px;
    width: 56%;
  }
}


@media (max-width: 959px) {
  .welcome {
    h2 {
      white-space: pre-line;
      margin-bottom: 5em;
      font-size: 2em;
    }

  }
}


@media (max-width: 600px) {
  .welcome {
    height: 600px;

    .overlay {
      height: 600px;
    }

    h2 {
      margin-bottom: 1.8rem;
      text-align: center;
    }

  }
}

@media (max-width: 375px) {
  .welcome {
    height: 500px;

    .overlay {
      height: 500px;
    }

  }
}

@media (max-width: 320px) {
  .welcome {
    height: 420px;

    .overlay {
      height: 420px;
    }

    h2 {
      font-size: 1.7em;
    }

  }
}



</style>