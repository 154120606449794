import Home from "@/views/Home";
import InvestmentOpportunities from "@/views/InvestmentOpportunities";
import ContactUs from "@/views/ContactUs";
import InvestmentApproach from "@/views/InvestmentApproach";
import VCC from "@/views/VCC";
import UKRealEstate from "@/views/UKRealEstate";

export default {
    root: "/",
    routes: [
        {
            name: "Home",
            path: "/",
            component: Home
        },
        {
            name: "Home",
            path: "/home",
            component: Home
        },
        {
            name: "InvestmentOpportunities",
            path: "/investment-opportunities",
            component: InvestmentOpportunities
        },
        {
            name: "InvestmentApproach",
            path: "/investment-approach",
            component: InvestmentApproach
        },
        {
            name: "VCC",
            path: "/vcc",
            component: VCC
        },
        {
            name: "UKRealEstate",
            path: "/uk-real-estate",
            component: UKRealEstate
        },
        {
            name: "ContactUs",
            path: "/contact-us",
            component: ContactUs
        },
    ]
}