<template>
  <v-dialog
      v-model="visible"
      max-width="300"
      :persistent="true"
  >
    <v-card>
      <v-card-title class="headline">
        {{ $store.getters.alertDialog.title }}
      </v-card-title>

      <v-card-text>
        {{ $store.getters.alertDialog.message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="primary"
            text
            @click="$store.commit('dismissAlertDialog')"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AlertDialog",
  computed: {
    visible: function () {
      return this.$store.getters.alertDialog.visible
    }
  },
}
</script>

<style scoped>

</style>