import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

//VueRouter
import VueRouter from 'vue-router'
import router from "./router";
Vue.use(VueRouter)
let vueRouter = new VueRouter(router)
//go to top after navigation
vueRouter.afterEach(() => {
  if (document.body.scrollTop !== 0) document.body.scrollTop = 0
  if (document.documentElement.scrollTop !== 0) document.documentElement.scrollTop = 0
})

import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    alertDialog: {
      visible: false,
      title: "",
      message: "",
    }
  },
  mutations: {
    showAlertDialog: function (state, payload) {
      state.alertDialog.visible = true
      state.alertDialog.message = payload.message
      state.alertDialog.title = payload.title
    },
    dismissAlertDialog: function (state) {
      state.alertDialog.visible = false
    }
  },
  getters: {
    alertDialog: state => state.alertDialog
  },
})

new Vue({
  vuetify,
  router: vueRouter,
  store: store,
  render: h => h(App)
}).$mount('#app')
