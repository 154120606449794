<template>
  <v-container>

    <v-row>

      <v-breadcrumbs
          :items="[{text: $vuetify.lang.t('$vuetify.navigation.insights')}, {text: $vuetify.lang.t('$vuetify.navigation.vcc')}]"
      ></v-breadcrumbs>

      <v-col
          cols="12"
          class="text-center mb-0"
      >
        <h1
            class="text-uppercase primary--text"
            style="font-weight: 300; font-size: 1.8em"
        >
          {{ $vuetify.lang.t('$vuetify.vcc.title') }}
        </h1>
        <h2
            style="font-weight: 300; font-size: 1.4em"
        >
          {{ $vuetify.lang.t(`$vuetify.vcc.subtitle`) }}
        </h2>
      </v-col>

      <v-col class="mb-2">
        <v-img src="@/assets/vcc.jpg" height="380"></v-img>
      </v-col>

      <v-col cols="12" class="pl-md-5">
        <p
            class="vcc-content"
            style="white-space: pre-line;"
            v-html="$vuetify.lang.t('$vuetify.vcc.content')">
        </p>
      </v-col>

      <v-col cols="12">
        <h3
            class="primary--text text-center font-weight-light mt-md-6 mt-2 font-italic"
            style="cursor: pointer"
            @click="$router.push('/uk-real-estate')"
        >
          <v-icon color="primary" small style="margin-top:-3px;">fas fa-arrow-right</v-icon>
          {{ $vuetify.lang.t(`$vuetify.vcc.visit`) }}
        </h3>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
export default {
  name: "VCC",
  created() {
    this.$vuetify.lang.locales.zhHans.vcc = {
      title: "可变动资本公司(VCC)",
      subtitle: "一种全新的新加坡基金公司架构",
      content: "新加坡可变动资本公司（VCC）架构由新加坡金融管理局（MAS）和新加坡会计与企业管理局（ACRA）于2020年1月15日推出，因其能服务于一系列传统和替代投资策略及结构的特性，为基金投资开创了全新的可能性。\n" +
          "\n" +
          "<b>什么是可变动资本公司（VCC）？</b>\n" +
          "\n" +
          "可变动资本公司（VCC）是一种全新的实体公司架构，在这种架构下，可在单一实体公司的伞形基金框架下架设若干相互独立的子基金/集体投资项目（开放式或封闭式均可），且每个子基金的资产及负债与可变动资本公司本身和其下其他子基金的资产及负债将分开核算。随着这一新架构的退推出，基金和财富管理公司将迎来一个新的时代。\n" +
          "\n" +
          "这种架构与英国的开放式投资公司架构，以及如格恩西岛、开曼群岛等地区的受保护细胞公司（PCC）等隔离式架构相类似。\n" +
          "\n" +
          "这一新型实体公司架构为基金提供了在单位信托基金（UT）、有限合伙、有限责任合伙和普通公司外的替代选择。\n",
      visit: "浏览英国地产",
    }
    this.$vuetify.lang.locales.en.vcc = {
      title: "The Variable Capital Company",
      subtitle: "A new corporate structure for funds in Singapore",
      content: "Launched by the Monetary Authority of Singapore (MAS) and the Accounting and Corporate Regulatory Authority (ACRA) on 15th January 2020, the Singapore Variable Capital Companies (VCC) framework provides a new corporate structure for investment funds as it caters to a range of both traditional and alternative investment strategies and structures. \n" +
          "\n" +
          "<b>What is a VCC?</b>\n" +
          "\n" +
          "The Variable Capital Company (VCC) is a new corporate entity structure under which several collective investment schemes (whether open-end or closed-end) may be gathered under the umbrella of a single corporate entity and remain ring fenced from each other. As this development stands poised to usher in a new era of options for fund and wealth managers.\n" +
          "\n" +
          "It is similar to open-ended investment company structure in the UK and protected cell company or segregated structures in jurisdictions like Guernsey or the Cayman Islands.\n" +
          "\n" +
          "The new corporate entity structure gives funds an alternative to unit trusts, limited partnerships, limited liability partnerships and companies.\n",
      visit: "Visit UK Real Estate",
    }
  }
}
</script>

<style lang="less" scoped>
.welcome {
  height: 380px;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.1)), url("~@/assets/vcc.jpg");

  h2 {
    color: #ffffff;
    line-height: 1.125;
    font-weight: 400;
    font-size: 2.5em;
    letter-spacing: .004em;
    font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    z-index: 1;
  }
}

.vcc-content {
  column-count: 2;
}

@media (max-width: 600px) {
  .vcc-content {
    column-count: 1;
  }
}
</style>