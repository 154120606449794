<template>
  <v-app>
    <navigation/>
    <v-main
        :class="{'pt-0': $route.name === 'Home'}"
    >
      <router-view/>
    </v-main>
    <foot/>

    <alert-dialog/>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation";
import Foot from "@/components/Foot";
import _ from 'lodash';
import AlertDialog from "@/components/AlertDialog";

export default {
  name: 'App',

  components: {
    AlertDialog,
    Foot,
    Navigation,
  },

  data: () => ({
    //
  }),

  created() {
    const _this = this
    this.$vuetify.lang.raw = function (key) {
      return _.get(_this.$vuetify.lang.locales[_this.$vuetify.lang.current], key.replace('$vuetify.', ''), null)
    }
  }
};
</script>
