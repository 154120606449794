<template>
  <v-container>

    <v-row>

      <v-breadcrumbs
          :items="[{text: $vuetify.lang.t('$vuetify.navigation.ourOffers')}, {text: $vuetify.lang.t('$vuetify.navigation.approach')}]"
      ></v-breadcrumbs>

      <v-col
          cols="12"
          class="text-center mb-0"
      >
        <h2
            class="text-uppercase primary--text"
            style="font-weight: 300; font-size: 1.8em"
        >
          {{ $vuetify.lang.t('$vuetify.investmentApproach.title') }}
        </h2>
      </v-col>

      <v-col class="mb-2">
        <v-img src="@/assets/investment-approach.jpg"></v-img>
      </v-col>

      <v-col cols="12" md="6" class="pl-md-5" style="white-space: pre-line">
        <p
            v-for="index in $vuetify.lang.raw('$vuetify.investmentApproach.content0').keys()"
            :key="'c-' + index"
        >
          {{ $vuetify.lang.t('$vuetify.investmentApproach.content0.' + index) }}
        </p>
      </v-col>

      <v-col cols="12" md="6" class="pr-md-5" style="white-space: pre-line">
        <p
            v-for="index in $vuetify.lang.raw('$vuetify.investmentApproach.content1').keys()"
            :key="'c-' + index"
            class="mb-1"
        >
          <v-icon
              x-small
              color="primary"
              class="mr-1"
              v-if="$vuetify.lang.t('$vuetify.investmentApproach.content1.' + index).startsWith(' ')"
          >fas fa-crosshairs
          </v-icon>
          {{ $vuetify.lang.t('$vuetify.investmentApproach.content1.' + index) }}
        </p>
      </v-col>

      <v-col cols="12">
        <h3
            class="primary--text text-center font-weight-light mt-md-6 mt-2 font-italic"
            style="cursor: pointer"
            @click="$router.push('/investment-opportunities')"
        >
          <v-icon color="primary" small style="margin-top:-3px;">fas fa-arrow-right</v-icon>
          {{ $vuetify.lang.t('$vuetify.investmentApproach.visit') }}
        </h3>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
export default {
  name: "InvestmentApproach",
  created() {
    this.$vuetify.lang.locales.zhHans.investmentApproach = {
      title: "投资理念",
      content0: [
        "在南洋环球资本，我们相信充分了解客户的独特投资需求和风险承担能力，是稳固长期合作关系的关键。公司能在控制风险的前提下，凭借我们海内外多元化的投资组合，根据客户的特定目标量身定制投资方案。",
        "在严密市场分析和全方位多维度模型的双重支持下，公司的项目甄选能力始终保持在业内前列水平。通过持续高效地引入更多具有增长潜力的储备项目，力保为客户提供最优质最契合的选择。在利用尖端数据和运营科技的同时，同样依赖于资源置换和协作发展带来的独特增值赋能属性，实现从单维项目选择到下一级的项目优化、进而提升收益的跃迁。",
      ],
      content1: [
        "公司的服务内容包括：",
        " 地产（投资、管理及开发）",
        " 私募股权",
        " 股票",
        " 财富管理",
        " 战略规划",
        " 投资组合管理",
        " 市场营销",
        " 海外资产管理及证券化",
        " 金融和法律咨询服务（高净值客户专属服务）",
        "\n南洋环球资本紧跟市场走势和经济趋势，为客户提供最具前瞻性的投资策略。",
      ],
      visit: "浏览投资策略",
    }
    this.$vuetify.lang.locales.en.investmentApproach = {
      title: "Investment Approach",
      content0: [
        "At Nanyang Global Capital VCC, we believe that truly appreciating the investment needs and risk-bearing capacity of our clientele, is the key to fostering solid relationships. With our diversified investment portfolio consisting of a wide range of domestic and foreign products, we create tailored investment solutions catering to our clients’ specific targets at a risk-controllable level.",
        "Through rigorous market analysis and comprehensive modelling, we continuously expand our portfolio adding growth-potential projects. We pride ourselves for having not only a strong track record sorting satisfactory targets but also incomparable value-creating power that helps promote our investments onto new levels. Being firm believers and practitioners of cutting edge operational and data tech, we also utilize human connections to achieve effective cooperation and unparalleled plans.",
      ],
      content1: [
        "Our Full Range of Services Includes",
        " Real estates",
        " PE",
        " Stock",
        " Wealth Management",
        " Strategic Planning",
        " Portfolio Management",
        " Marketing Solutions",
        " Overseas Asset Management & Securitization",
        " Financial and Legal Advisory Services to Exclusive Clients",
        "\nWe continuously develop strategies that are in line with the market movements and economic trends.",
      ],
      visit: "Visit Investment Opportunities & Solutions",
    }
  }
}
</script>

<style lang="less" scoped>
.welcome {
  height: 380px;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.1)), url("~@/assets/investment-approach.jpg");

  h2 {
    color: #ffffff;
    line-height: 1.125;
    font-weight: 400;
    font-size: 2.5em;
    letter-spacing: .004em;
    font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    z-index: 1;
  }
}
</style>