<template>
  <v-container>
    <v-row>
      <v-col
          cols="12"
          class="mt-md-10 mb-md-7 text-center"
      >
        <h2
            class="text-uppercase primary--text"
            style="font-weight: 300; font-size: 1.8em"
        >
          {{ $vuetify.lang.t('$vuetify.contactUs.title') }}
        </h2>
      </v-col>

      <v-col
          cols="12"
          md="5"
          class="pr-md-0 pb-md-3 pb-0"
      >
        <v-card
            class="elevation-0 pa-md-10 pa-5 contact contact-left text-center"
            style="background-color: #E3F2FD"
        >
          <h3
              class="mb-3 primary--text"
          >
            <v-icon
                color="primary"
                class="mr-1"
                medium
            >fas fa-envelope
            </v-icon>
            {{ $vuetify.lang.t(`$vuetify.contactUs.email`) }}
          </h3>
          <p class="pl-1">
            <a href="mailto:support@ngc-sg.com">support@ngc-sg.com</a>
          </p>


          <h3
              class="mt-md-10 mt-5 mb-3 primary--text"
          >
            <v-icon
                color="primary"
                class="mr-1"
                medium
            >fas fa-map-marker-alt
            </v-icon>
            {{ $vuetify.lang.t(`$vuetify.contactUs.address`) }}
          </h3>
          <p class="pl-1">
            1 Raffles Place<br>
            #02-01, One Raffles Place<br>
            Singapore 048616
          </p>

          <div class="mapouter mt-md-10 mt-5">
            <div class="gmap_canvas" style="height: 500px">
              <iframe v-show="!mapLoading" style="width: 100%" height="500" id="gmap_canvas" @load="onMapLoad"
                      src="https://maps.google.com/maps?q=One%20Raffles%20Place%20Singapore%20048616&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
              <div v-show="mapLoading" class="map-loader">
                <v-progress-circular
                    indeterminate
                    color="primary lighten-3"
                    style="margin-top: 230px;"
                ></v-progress-circular>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col
          cols="12"
          md="7"
          class="pl-md-0 pt-md-3 pt-0"
      >
        <v-card
            class="elevation-0 contact contact-right pa-md-10 pa-5"
        >
          <v-form
              ref="message"
              @submit.prevent="sendMessage"
          >
            <v-row>
              <v-col cols="12" class="pb-0">
                {{ $vuetify.lang.t(`$vuetify.contactUs.intro`) }}:<br>
                <i class="grey--text">* {{$vuetify.lang.t(`$vuetify.contactUs.indication`)}}</i>
              </v-col>
              <!--              <v-col md="5" cols="12">-->
              <!--                <v-row>-->
              <v-col md="12" cols="12">
                <v-select
                    :label="$vuetify.lang.t(`$vuetify.contactUs.personalTitle`)"
                    outlined
                    hide-details
                    :items="$vuetify.lang.raw(`$vuetify.contactUs.personalTitleList`)"
                    v-model="message.title"
                    :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                    :label="$vuetify.lang.t(`$vuetify.contactUs.firstName`)"
                    outlined
                    hide-details
                    v-model="message.first_name"
                    :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                    :label="$vuetify.lang.t(`$vuetify.contactUs.surname`)"
                    outlined
                    hide-details
                    v-model="message.last_name"
                    :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                    :label="$vuetify.lang.t(`$vuetify.contactUs.emailAddress`)"
                    outlined
                    hide-details
                    v-model="message.email"
                    :rules="[rules.email]"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                    :label="$vuetify.lang.t(`$vuetify.contactUs.companyName`)"
                    outlined
                    hide-details
                    v-model="message.company"
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <v-autocomplete
                    :label="$vuetify.lang.t(`$vuetify.contactUs.countryCode`)"
                    outlined
                    hide-details
                    :items="countryCodes"
                    :item-value="item => item.name"
                    :item-text="item => item.name +' (' + item.iso2 + ') +'+ item.dialCode"
                    :filter="regionCodeQuery"
                    v-model="countryName"
                    :menu-props="{ auto: true, offsetY: true }"
                    :searchable="false"
                >
                </v-autocomplete>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                    :label="$vuetify.lang.t(`$vuetify.contactUs.contactNumber`)"
                    outlined
                    hide-details
                    v-model="message.contact_number"
                ></v-text-field>
              </v-col>
              <!--                </v-row>-->
              <!--              </v-col>-->
              <v-col md="12" cols="12">
                <v-textarea
                    :label="$vuetify.lang.t(`$vuetify.contactUs.message`)"
                    outlined
                    height="216"
                    v-model="message.message"
                    :rules="[rules.required]"
                ></v-textarea>
                <v-btn
                    color="primary"
                    class="float-md-right"
                    :loading="sendMessageBtnLoading"
                    type="submit"
                >
                  {{ $vuetify.lang.t(`$vuetify.contactUs.submit`) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>

      <v-col>
        <p class="grey--text text--darken-2 font-weight-light text-center font-italic body-2">
          {{ $vuetify.lang.t(`$vuetify.contactUs.tips`) }}
        </p>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import countries from "@/country-code";

import axios from 'axios';
// import axiosRetry from 'axios-retry';
// axiosRetry(axios, {
//   retries: 3,
//   shouldResetTimeout: true,
//   retryCondition: (error) => {
//     return error.response.status !== 200;
//   },
// });

export default {
  name: "ContactUs",
  data: () => ({
    message: {
      title: "",
      first_name: "",
      second_name: "",
      email: "",
      company: "",
      country_code: "",
      contact_number: "",
      message: "",
    },
    mapLoading: true,
    countryName: "Singapore",
    countryCodes: countries,
    sendMessageBtnLoading: false,
    rules: {
      required: value => !!value || 'Required.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid email.'
      },
    },
  }),
  methods: {
    regionCodeQuery: function (item, queryText) {
      let keyword = queryText.toLowerCase()
      return item.name.toLowerCase().startsWith(keyword) ||
          item.dialCode.startsWith(keyword) ||
          item.iso2.toLowerCase().indexOf(keyword) > -1 ||
          (item.name === 'United Kingdom' && keyword === "uk")
    },
    sendMessage: function () {
      if (this.countryName) {
        this.message.country_code = this.countryCodes.find(item => item.name === this.countryName).dialCode
      }

      if (!this.$refs["message"].validate()) {
        return
      }
      this.sendMessageBtnLoading = true

      axios.post("https://api.ngc-sg.com/email", this.message, {timeout: 18000}).then(() => {
        this.$store.commit("showAlertDialog", {
          title: "Message Sent",
          message: "Thank you for your message, a member of our team will contact you soon."
        })
        this.$refs["message"].reset()
      }).catch(err => {
        console.log(err)
        this.$store.commit("showAlertDialog", {
          title: "Error",
          message: "Fail to send the message. Sorry for the trouble, please try again or you may directly contact support@ngc-sg.com instead."
        })
      }).finally(() => {
        this.sendMessageBtnLoading = false
      })
    },
    onMapLoad: function () {
      this.mapLoading = false
    },
  },
  created() {
    this.$vuetify.lang.locales.zhHans.contactUs = {
      "title": "联系我们",
      "intro": "如需预约会面，请填写以下表格",
      "address": "办公地址",
      "email": "邮箱地址",
      "submit": "发送消息",
      "personalTitle": "称谓*",
      "personalTitleList": ['先生', '夫人', '女士', '小姐', '博士'],
      "firstName": "名*",
      "surname": "姓*",
      "emailAddress": "邮箱地址*",
      "companyName": "公司名称",
      "countryCode": "国家代码",
      "contactNumber": "联系电话",
      "message": "消息内容*",
      "tips": "请阅读我们的隐私政策，以了解更多信息。",
      "indication":"代表必填项",
    }
    this.$vuetify.lang.locales.en.contactUs = {
      "title": "Contact Us",
      "intro": "To request a meeting please complete the form below",
      "address": "Office Address",
      "email": "Contact Email",
      "submit": "Send Message",
      "personalTitle": "Title*",
      "personalTitleList": ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr'],
      "firstName": "First Name*",
      "surname": "Surname*",
      "emailAddress": "Email Address*",
      "companyName": "Company Name",
      "countryCode": "Country Code",
      "contactNumber": "Contact Number",
      "message": "Message*",
      "tips": "Please read our Privacy Policy for more information.",
      "indication":"Indicates required field.",
    }
  }
}
</script>

<style lang="less" scoped>
.contact {
  height: 100%;
  border: 1px solid #d2d2d7;
  border-radius: 0;
}

.contact-left {
  border-right: 0;
}

.map-loader {
  height: 500px;
  background-color: #EEEEEE;
}

@media (max-width: 959px) {

  .contact-left {
    border-bottom: 0;
    border-right: 1px solid #d2d2d7;
  }

}
</style>