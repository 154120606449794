<template>
  <v-container>

    <v-breadcrumbs
        :items="[{text: $vuetify.lang.t('$vuetify.navigation.ourOffers')}, {text: $vuetify.lang.t('$vuetify.navigation.opportunities')}]"
        class="pl-3"
    ></v-breadcrumbs>

    <v-row justify="center">


      <!-- title -->
      <v-col
          cols="12"
          class="mb-3 text-center"
      >
        <h2
            class="text-uppercase primary--text"
            style="font-weight: 300; font-size: 1.8em"
        >
          {{ $vuetify.lang.t('$vuetify.investmentOpportunities.title') }}
        </h2>
      </v-col>


      <!-- layout 1 -->
      <v-col
          cols="12"
          v-for="index in $vuetify.lang.raw('$vuetify.investmentOpportunities.services').keys()"
          :key="'opp-0-' + index"
          class="d-none mb-6 px-md-0 px-lg-4"
      >
        <v-card
            v-if="false"
            style="background-color: #E3F2FD" class="elevation-0">
          <v-img class="d-sm-none"
                 :src="require('@/assets/' + $vuetify.lang.t(`$vuetify.investmentOpportunities.services.${index}.img`))"></v-img>
          <v-list-item two-line class="px-0">
            <v-list-item-avatar
                v-if="index % 2 === 0"
                tile
                class="d-none d-sm-block my-0 mx-0"
                style="width:355px; height: 280px"
            >
              <v-img
                  :src="require('@/assets/' + $vuetify.lang.t(`$vuetify.investmentOpportunities.services.${index}.img`))"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="px-5">
              <v-list-item-title class="text-h5 mb-5 mt-3 mt-sm-0 text-center">
                <v-icon class="mr-1" color="primary" style="margin-top: -5px">
                  {{ $vuetify.lang.t(`$vuetify.investmentOpportunities.services.${index}.icon`) }}
                </v-icon>
                {{ $vuetify.lang.t(`$vuetify.investmentOpportunities.services.${index}.title`) }}
              </v-list-item-title>
              <div class="text-body-1">
                {{ $vuetify.lang.t(`$vuetify.investmentOpportunities.services.${index}.desc`) }}
              </div>
            </v-list-item-content>

            <v-list-item-avatar
                v-if="index % 2 === 1"
                tile
                class="d-none d-sm-block my-0 mx-0"
                style="width:355px; height: 280px"
            >
              <v-img
                  :src="require('@/assets/' + $vuetify.lang.t(`$vuetify.investmentOpportunities.services.${index}.img`))"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>

      <!-- layout 2 -->
      <v-col
          cols="12"
          sm="6"
          lg="5"
          v-for="index in $vuetify.lang.raw('$vuetify.investmentOpportunities.services').keys()"
          :key="'opp-' + index"
      >
        <v-card
            class="elevation-2"
            style="height: 100%">
          <v-img
              class="white--text align-end"
              height="230"
              :src="require('@/assets/' + $vuetify.lang.t(`$vuetify.investmentOpportunities.services.${index}.img`))"
              gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,.6)"
          >
            <v-card-title>
              <v-icon class="mr-3" color="white">
                {{ $vuetify.lang.t(`$vuetify.investmentOpportunities.services.${index}.icon`) }}
              </v-icon>
              {{ $vuetify.lang.t(`$vuetify.investmentOpportunities.services.${index}.title`) }}
            </v-card-title>
          </v-img>
          <v-card-text>
            <div class="body-1 grey--text text--darken-4" style="word-break: break-word; white-space: pre-line">
              {{ $vuetify.lang.t(`$vuetify.investmentOpportunities.services.${index}.desc`) }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- layout 3 -->
      <v-col
          class="d-none mb-1"
          cols="12"
          md="6"
          v-for="index in $vuetify.lang.raw('$vuetify.investmentOpportunities.services').keys()"
          :key="'opportunity-' + index"
      >
        <v-card
            v-if="false"
            class="service elevation-0 pa-5"
        >
          <h3
              class="mb-3 primary--text"
          >
            <v-icon
                color="primary"
                class="mr-3"
                medium
                style="width: 24px;"
            >{{ $vuetify.lang.t(`$vuetify.investmentOpportunities.services.${index}.icon`) }}
            </v-icon>
            {{ $vuetify.lang.t(`$vuetify.investmentOpportunities.services.${index}.title`) }}
          </h3>
          <p
              class="mb-0"
          >
            {{ $vuetify.lang.t(`$vuetify.investmentOpportunities.services.${index}.desc`) }}
          </p>
        </v-card>
      </v-col>

      <v-col cols="12">
        <h3
            class="primary--text text-center font-weight-light mt-md-6 mt-2 font-italic"
            style="cursor: pointer"
            @click="$router.push('/investment-approach')"
        >
          <v-icon color="primary" small style="margin-top:-3px;">fas fa-arrow-right</v-icon>
          {{ $vuetify.lang.t('$vuetify.investmentOpportunities.visit') }}
        </h3>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "InvestmentOpportunities",
  created() {
    this.$vuetify.lang.locales.zhHans.investmentOpportunities = {
      title: "投资机会和策略",
      services: [
        {
          title: "地产",
          desc: "地产项目因其高收益低风险的投资特性以及可转化为各类金融产品的灵活持有方式，一直以来受到广大投资机构和个人投资者青睐。从传统的直投模式到后来的贷款、信托、保险等新一代产品，地产及其衍生品在金融投资领域占据着举足轻重的地位。\n" +
              "\n" +
              "南洋环球资本与中英两国地产开发公司和物业管理公司均有着密切合作，并于近期推出了英国商业地产的中短期债权产品，收益稳定有保障，退出方式灵活。公司将持续为有意进军地产市场的客户打造更安全、更全面的地产投资及开发方案。\n",
          icon: "fas fa-building",
          img: "opp-real-estate.jpg",
        },
        {
          title: "直投",
          desc: "依托于管理团队的丰富经验和多元背景，南洋环球资在海内外对接储备了大量优质投资项目，即有致力于尖端科技研发的初创企业，也有模式成熟的顶尖上市公司，能够为高净值客户提供定制的私募股权/股票证券投资方案。\n" +
              "\n" +
              "此外，南洋环球资本也一直致力于打造服务于亚欧两地产业基金和企业孵化平台的产融合作生态圈。凭借对海外资产甄选和跨境投资交易的深度了解，南洋环球资本始终竭诚为客户和合作伙伴提供最高质量且最具前瞻性的投资机会。目前与多家英美尖端医科公司开展了深度合作，前中后期项目均有储备，为各级合作方和投资人提供了不同的投资机会。\n",
          icon: "fas fa-dollar-sign",
          img: "opp-direct-investment.png",
        },
        {
          title: "QFLP",
          desc: "随着外商投资股权投资企业 (‘QFLP’)相关政策进一步放宽，南洋环球资本正在积极推进与中国内地各大金融机构的联合试点项目，以境外LP身份，打通境外资本参投中国非上市公司股权及上市公司非公开发行和交易的普通股的路径。目前已接洽了科创板企业十数家，可为客户提供包括定向发行新股、大宗交易、协议转让等各类投资机会。\n" +
              "\n" +
              "QFLP是近年来中国内地为吸引外商投资而推出的投资架构之一，目前只在少数金融改革地区试行，受地方不同政策限制。这一架构可适用于境内外募资，且在外汇结算方面为海外投资人提供了更优惠的待遇。在新政策进一步扩大投资范围、降低准入门槛的导向下，QFLP将为海外资本提供更便捷也更丰富的投资选择。\n",
          icon: "fas fa-chart-line",
          img: "opp-qflp.jpg",
        },
        {
          title: "增值服务",
          desc: "凭借自身的多点布局策略和遍及各地区、各领域的合作方资源，南洋环球资本结合战略规划、品牌定位、资源匹配、改造升级、专业管理和运营优化等手段激发投资标的独特的增值潜能，从而打造收益稳定的低风险投资组合，满足客户和合作伙伴的需求。",
          icon: "fas fa-user-tie",
          img: "opp-exclusive-service.jpg",
        }
      ],
      visit: "浏览投资理念",
    }
    this.$vuetify.lang.locales.en.investmentOpportunities = {
      title: "Investment Opportunities and Solutions",
      services: [
        {
          title: "Real Estate",
          desc: "Real estate has always been a popular asset class among many institutional and private investors for its attractive and yet stable financial characteristics as well as its potential to be transformed into various product types. From the old school direct investment to newer concepts like loan, trusts, insurance, etc., real estate derivatives cover all.\n" +
              "\n" +
              "We work closely with UK and China-based real estate developers and management service providers to develop safer and more comprehensive solutions on selected properties and development plots, catered to our clients who are interested in entering the real estate market. We have recently developed a short-mid term debt product with equity option based on UK hospitality properties, offering fixed return and flexible exit strategies.\n",
          icon: "fas fa-building",
          img: "opp-real-estate.jpg",
        },
        {
          title: "Direct Investment",
          desc: "Relying on our experienced team and diverse resources, we are able to offer exciting PE/stock investment opportunities to our private clients, from startups working on life-changing ideas to top-performing companies listed all over the world.\n" +
              "\n" +
              "We also provide customized services for industrial funds and business incubators in Asia and Europe. With extensive knowledge of foreign asset selection and cross-border investment transaction, we are committed to ensure that all our clients and partners receive the highest quality and most forward-looking investment opportunities. We are currently working with a number of medical tech companies based in UK and US with products at various stages, welcoming investors and potential partners to participate.\n",
          icon: "fas fa-dollar-sign",
          img: "opp-direct-investment.png",
        },
        {
          title: "QFLP",
          desc: "Following the recent policies relaxation, we are piloting QFLP programmes with mainland Chinese banks and institutions, to enable foreign capital to access broader range of Chinese assets classes, including not only PE but also preferred stocks, private placements, convertible bonds, distressed assets, etc. With our strong mainland network, we have already secured over a dozen Star Market projects in the pipeline.\n" +
              "\n" +
              "QFLP is one of the newest types of vehicles for inbound investments into mainland China, which is available only in a few financial reform areas at the moment, subject to different local restrictions. It provides more favorable treatment to foreign fund managers and institutional investors with respect to FOREX settlement, allowing both onshore and offshore fundraising.\n",
          icon: "fas fa-chart-line",
          img: "opp-qflp.jpg",
        },
        {
          title: "Exclusive Services",
          desc: "With regional teams and partners ready to help promote each target enterprise's unique value-adding potential through strategic planning, brand building, resource matching, renovation, redevelopment, professional management, and operational optimization. We are able to achieve low-risk investment portfolios with stable returns, and support our clients and partners needs.",
          icon: "fas fa-user-tie",
          img: "opp-exclusive-service.jpg",
        }
      ],
      visit: "Visit Investment Approach",
    }
  }
}
</script>

<style lang="less" scoped>
.service {
  height: 100%;
  //background-color: #fafafa !important;

  h3 {
    .v-icon {
      margin-top: -3px;
    }
  }

  p {
    text-align: left;
  }
}
</style>