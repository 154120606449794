<template>
  <v-container>

    <v-breadcrumbs
        :items="[{text: $vuetify.lang.t('$vuetify.navigation.insights')}, {text: $vuetify.lang.t('$vuetify.navigation.ukRealEstate')}]"
        class="pl-3"
    ></v-breadcrumbs>

    <v-row justify="center">

      <!-- title -->
      <v-col
          cols="12"
          class="mb-3 text-center"
      >
        <h2
            class="text-uppercase primary--text"
            style="font-weight: 300; font-size: 1.8em"
        >
          {{ $vuetify.lang.t('$vuetify.ukRealEstate.title') }}
        </h2>
      </v-col>


      <!-- layout 1 -->
      <v-col
          cols="12"
          v-for="index in $vuetify.lang.raw('$vuetify.ukRealEstate.services').keys()"
          :key="'opp-0-' + index"
          class="d-none mb-4 px-lg-4"
      >
        <v-card
            v-if="false"
            style="background-color: #E3F2FD" class="elevation-0">
          <v-img class="d-sm-none" style="height: 210px" :src="require('@/assets/' + $vuetify.lang.t(`$vuetify.ukRealEstate.services.${index}.img`))"></v-img>
          <v-list-item two-line class="px-0">
            <v-list-item-avatar
                v-if="index % 2 === 0"
                tile class="d-none d-sm-block my-0 mx-0 pl-lg-0 pl-4" style="width:370px; height: 245px">
              <v-img :src="require('@/assets/' + $vuetify.lang.t(`$vuetify.ukRealEstate.services.${index}.img`))"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="px-5">
              <v-list-item-title class="text-h5 mb-5 mt-3 text-center">
                <v-icon class="mr-1" color="primary" style="margin-top: -5px">{{ $vuetify.lang.t(`$vuetify.ukRealEstate.services.${index}.icon`) }}</v-icon>
                {{ $vuetify.lang.t(`$vuetify.ukRealEstate.services.${index}.title`) }}
              </v-list-item-title>
              <div class="text-body-1">
                {{ $vuetify.lang.t(`$vuetify.ukRealEstate.services.${index}.desc`) }}
              </div>
            </v-list-item-content>

            <v-list-item-avatar
                v-if="index % 2 === 1"
                tile class="d-none d-sm-block my-0 mx-0 pr-lg-0 pr-4" style="width:370px; height: 245px">
              <v-img :src="require('@/assets/' + $vuetify.lang.t(`$vuetify.ukRealEstate.services.${index}.img`))"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>

      <!-- layout 2 -->
      <v-col
          cols="12"
          sm="6"
          lg="5"
          v-for="index in $vuetify.lang.raw('$vuetify.ukRealEstate.services').keys()"
          :key="'opp-' + index"
      >
        <v-card
            class="elevation-2"
            style="height: 100%">
          <v-img
              class="white--text align-end"
              height="230"
              :src="require('@/assets/' + $vuetify.lang.t(`$vuetify.ukRealEstate.services.${index}.img`))"
              gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,.6)"
          >
            <v-card-title>
              <v-icon class="mr-3" color="white">
                {{ $vuetify.lang.t(`$vuetify.ukRealEstate.services.${index}.icon`) }}
              </v-icon>
              {{ $vuetify.lang.t(`$vuetify.ukRealEstate.services.${index}.title`) }}
            </v-card-title>
          </v-img>
          <v-card-text>
            <div class="body-1 grey--text text--darken-4" style="word-break: break-word; white-space: pre-line">
              {{ $vuetify.lang.t(`$vuetify.ukRealEstate.services.${index}.desc`) }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <h3
            class="primary--text text-center font-weight-light mt-md-6 mt-2 font-italic"
            style="cursor: pointer"
            @click="$router.push('/vcc')"
        >
          <v-icon color="primary" small style="margin-top:-3px;">fas fa-arrow-right</v-icon>
          {{ $vuetify.lang.t(`$vuetify.ukRealEstate.visit`) }}
        </h3>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UKRealEstate",
  created() {
    this.$vuetify.lang.locales.zhHans.ukRealEstate = {
      title: "英国地产",
      services: [
        {
          title: "酒店",
          desc: "在英国，酒店行业一直以满足客户持续升级的需求为目标而不断发展。南洋环球资本致力于通过对国际旅游趋势的系统性分析，为酒店投资者提供各类优质机会。除将主办2022年英联邦运动会的伯明翰地区之外，伦敦、布莱顿、爱丁堡和约克等地区也始终保持在全英最具吸引力城市排名的前列，这些地区的旅游业在后疫情时代将迎来爆发式增长，进而推动英国酒店地产产业的机遇性发展。",
          icon: "fas fa-bath",
          img: "uk-real-estate-hotel.jpg",
        },
        {
          title: "住宅",
          desc: "在英国的大部分地区，住宅地产可购性仍十分具有吸引力，且持续的经济增长支撑起了不断增长的住房需求，使得住宅地产销售周期缩短、估值稳步上涨。同时，英国住宅市场也非常多样化，为需求不同的各类投资者提供了大量的机会。在南洋环球资本，我们与多家经验丰富的知名住宅地产供应方合作，为客户提供尽可能多的支持和保障。",
          icon: "fas fa-home",
          img: "uk-real-estate-residential.jpg",
        },
      ],
      visit: "浏览VCC可变动资本公司",
    }
    this.$vuetify.lang.locales.en.ukRealEstate = {
      title: "UK Real Estate",
      services: [
        {
          title: "Hotel",
          desc: "Within the United Kingdom, the hotel sector has continued to evolve to meet the dynamically changing needs of customers. At Nanyang Global VCC we are committed to offering various opportunities for hotel investors through detailed analysis of international tourism trends. Alongside Birmingham, which is set to host the 2022 Commonwealth Games, London, Brighton, Edinburgh, and York are some of the most attractive cities across the country set to see a buoyant rise in tourism; therefore encouraging an opportunistic boost in the UK hotel real estate sector.",
          icon: "fas fa-bath",
          img: "uk-real-estate-hotel.jpg",
        },
        {
          title: "Residential",
          desc: "In large parts of the UK housing affordability remains attractive, and continued economic growth is supporting housing demand in this ever growing market. This is resulting in shorter sales periods and lower discounts to asking prices. The UK residential market is very diverse and therefore offers a dynamic amount of opportunities for investment. We work with experienced residential providers within the UK to offer our clientele as much support as we can.",
          icon: "fas fa-home",
          img: "uk-real-estate-residential.jpg",
        },
      ],
      visit: "Visit VCC",
    }
  }
}
</script>

<style lang="less" scoped>
.service {
  height: 100%;
  //background-color: #fafafa !important;

  h3 {
    .v-icon {
      margin-top: -3px;
    }
  }

  p {
    text-align: left;
  }
}
</style>