<template>
  <v-footer
      id="footer"
      class="py-8 mt-md-16 mt-10"
      color="#fafafa"
  >
    <v-container>

      <v-row
          class="px-4"
      >
        <v-col
            sm="5"
            cols="12"
        >
          <h2 class="mb-1">NGC VCC</h2>
          <p>
            @Copyright 2021 Nanyang Global Capital VCC. <br>
            {{ $vuetify.lang.t('$vuetify.footer.rights') }}<br>
            UEN: T21VC0069C
          </p>
          <p></p>
        </v-col>

        <v-spacer/>

        <v-col
            sm="3"
            cols="12"
        >
          <h4 class="mb-3">
            {{ $vuetify.lang.t('$vuetify.footer.legal') }}
          </h4>
          <p
              class="mb-2 link"
              @click="termsDialog = true"
          >
            {{ $vuetify.lang.t('$vuetify.footer.terms') }}
          </p>
          <p
              class="mb-2 link"
              @click="privacyDialog = true"
          >
            {{ $vuetify.lang.t('$vuetify.footer.privacy') }}
          </p>
        </v-col>

        <v-col
            class="d-none"
            sm="2"
            cols="12"
        >
          <h4 class="mb-3">
            {{ $vuetify.lang.t('$vuetify.footer.help') }}
          </h4>
          <p
              class="mb-2 link"
              @click="contactDialog = true"
          >
            {{ $vuetify.lang.t('$vuetify.footer.contactUs') }}
          </p>
        </v-col>
      </v-row>

      <!-- Terms & Conditions -->
      <v-dialog
          v-model="termsDialog"
          scrollable
          width="auto"
          max-width="800px"
      >
        <v-card>
          <v-card-title
          >
            Terms and Conditions of use
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text
              class="term-content pt-md-10 pt-7"
              v-html="$vuetify.lang.t('$vuetify.footer.termsContent')"
          >
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
                text
                class="mx-auto"
                color="primary"
                @click="termsDialog = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Privacy -->
      <v-dialog
          v-model="privacyDialog"
          scrollable
          width="auto"
          max-width="800px"
      >
        <v-card>
          <v-card-title>
            Privacy Notice
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text
              class="term-content pt-md-10 pt-7"
              v-html="$vuetify.lang.t('$vuetify.footer.privacyContent')"
          >
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
                text
                class="mx-auto"
                color="primary"
                @click="privacyDialog = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Contact us -->
      <v-dialog
          v-model="contactDialog"
          scrollable
          :persistent="true"
          width="auto"
          max-width="850px"
      >
        <v-card>
          <v-card-title
          >
            Contact Us
            <v-btn
                outlined
                class="ml-auto mr-3"
                color="primary"
            >
              Submit
            </v-btn>
            <v-btn
                outlined
                @click="contactDialog = false"
            >
              Close
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text
              class="mt-8 mb-0"
          >
            <v-form>
              <v-row>
                <v-col
                    md="4"
                    cols="12"
                    class="py-0"
                >
                  <v-text-field
                      outlined
                      label="Name"
                      :rules="[rules.required]"
                  >
                  </v-text-field>
                  <v-text-field
                      outlined
                      label="Email"
                      :rules="[rules.required, rules.email]"
                  >
                  </v-text-field>
                  <v-text-field
                      outlined
                      label="Contact Number (optional)"
                  >
                  </v-text-field>
                  <v-text-field
                      outlined
                      label="Company Name (optional)"
                  >
                  </v-text-field>
                </v-col>

                <v-col
                    md="8"
                    cols="12"
                    class="py-0"
                >
                  <v-text-field
                      outlined
                      label="Subject"
                      :rules="[rules.required]"
                  >
                  </v-text-field>
                  <v-textarea
                      outlined
                      label="Something you want tell us"
                      :rules="[rules.required]"
                      height="228px"
                  >
                  </v-textarea>
                </v-col>

              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

    </v-container>
  </v-footer>
</template>

<script>
import {EventBus} from "@/EventBus";

let TermsDocEN = require("@/components/TermsDocEN.htm");
let TermsDocZhHans = require("@/components/TermsDocZhHans.htm");
let PrivacyDocEN = require("@/components/PrivacyDocEN.htm");
let PrivacyDocZhHans = require("@/components/PrivacyDocZhHans.htm");

export default {
  name: "Foot",
  data: () => ({
    termsDialog: false,
    privacyDialog: false,
    contactDialog: false,
    rules: {
      required: value => !!value || 'Required.',
      counter: value => value.length <= 20 || 'Max 20 characters',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
    },
  }),
  created() {
    this.$vuetify.lang.locales.en.footer = {
      rights: "All right reserved.",
      legal: "Legal",
      terms: "Terms & conditions",
      termsContent: TermsDocEN,
      privacy: "Privacy policy",
      privacyContent: PrivacyDocEN,
      help: "Help",
      contactUs: "Contact us",
    }
    this.$vuetify.lang.locales.zhHans.footer = {
      rights: "保留所有权利。",
      legal: "法律信息",
      terms: "使用条款",
      termsContent: TermsDocZhHans,
      privacy: "隐私政策",
      privacyContent: PrivacyDocZhHans,
      help: "帮助",
      contactUs: "联系我们",
    }
  },
  mounted() {
    EventBus.$on('show-privacy-policy', () => {
      this.privacyDialog = true
    })
  },
  beforeDestroy() {
    EventBus.$off('show-privacy-policy')
  }
}
</script>

<style scoped>
.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

table, th, td {
  border: 1px solid #dddddd;
  border-collapse: collapse;
}

td {
  padding: 3px;
}
</style>
<style>
.term-content ul, .term-content ol {
  padding-left: 0;
}
</style>