<template>
  <div>
    <v-app-bar
        app
        :color="showBackground ? 'white' : 'transparent'"
        class="px-5"
        :class="{
          'scroll-down': showBackground,
          'elevation-3': showBackground,
          'elevation-0': !showBackground,
          'lang-zhHans': $vuetify.lang.current === 'zhHans'
        }"
    >
      <v-app-bar-nav-icon
          @click="drawer = true"
      >
      </v-app-bar-nav-icon>

      <div class="logo d-flex align-center" @click="$router.push('/')" style="cursor: pointer">
        <span>
          NGC VCC
        </span>
        <span
            class="ml-2 full-name text-center"
        >
        Nanyang Global Capital VCC
      </span>

      </div>

      <v-spacer></v-spacer>

      <v-btn
          text
          to="/"
      >
        {{ $vuetify.lang.t('$vuetify.navigation.home') }}
      </v-btn>
      <v-menu
          open-on-hover
          bottom
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              text
              v-bind="attrs"
              v-on="on"
          >
            {{ $vuetify.lang.t('$vuetify.navigation.ourOffers') }}
            <v-icon x-small class="ml-1">fas fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list class="py-0">
          <v-list-item
              to="/investment-approach"
          >
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.approach') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
              to="/investment-opportunities"
          >
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.opportunities') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
          open-on-hover
          bottom
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              text
              v-bind="attrs"
              v-on="on"
          >
            {{ $vuetify.lang.t('$vuetify.navigation.insights') }}
            <v-icon x-small class="ml-1">fas fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list class="py-0">
          <v-list-item
              to="/vcc"
          >
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.vcc') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
              to="/uk-real-estate"
          >
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.ukRealEstate') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
          text
          to="/contact-us"
      >
        {{ $vuetify.lang.t('$vuetify.navigation.contactUs') }}
      </v-btn>
      <v-menu
          open-on-hover
          bottom
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              text
              v-bind="attrs"
              v-on="on"
          >
            {{ $vuetify.lang.t('$vuetify.navigation.language') }}
            - {{ $vuetify.lang.current === 'en' ? 'en' : '中文' }}
            <v-icon x-small class="ml-1">fas fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list class="py-0">
          <v-list-item
              @click="changeLanguage('zhHans')"
              :class="{'font-weight-bold': $vuetify.lang.current === 'zhHans'}"
          >
            <v-list-item-title>中文</v-list-item-title>
          </v-list-item>
          <v-list-item
              @click="changeLanguage('en')"
              :class="{'font-weight-bold': $vuetify.lang.current === 'en'}"
          >
            <v-list-item-title>English</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            active-class="text--accent-4"
        >
          <v-list-item to="/">
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.home') }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/#about-us">
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.aboutUs') }}</v-list-item-title>
          </v-list-item>
          <v-list-group
              no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $vuetify.lang.t('$vuetify.navigation.ourOffers') }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                to="/investment-approach"
            >
              <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.approach') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
                to="/investment-opportunities"
            >
              <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.opportunities') }}</v-list-item-title>
            </v-list-item>

          </v-list-group>
          <v-list-group
              no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $vuetify.lang.t('$vuetify.navigation.insights') }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                to="/vcc"
            >
              <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.vcc') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
                to="/uk-real-estate"
            >
              <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.ukRealEstate') }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item to="/contact-us">
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.contactUs') }}</v-list-item-title>
          </v-list-item>
          <v-list-group
              v-model="drawerLanguageDropdown"
              no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $vuetify.lang.t('$vuetify.navigation.language') }}
                  - {{ $vuetify.lang.current === 'en' ? 'en' : '中文' }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                @click="changeLanguage('zhHans')"
                :class="{'font-weight-bold': $vuetify.lang.current === 'zhHans'}"
            >
              <v-list-item-title>中文</v-list-item-title>
            </v-list-item>
            <v-list-item
                @click="changeLanguage('en')"
                :class="{'font-weight-bold': $vuetify.lang.current === 'en'}"
            >
              <v-list-item-title>English</v-list-item-title>
            </v-list-item>

          </v-list-group>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

  </div>
</template>

<script>
export default {
  name: "Navigation",
  data: () => ({
    appBarScrollDown: false,
    drawer: false,
    drawerLanguageDropdown: false,
  }),
  computed: {
    showBackground() {
      return this.appBarScrollDown || this.$route.name !== 'Home'
    }
  },
  mounted() {
    window.onscroll = () => {
      if (document.body.scrollTop > 130 ||
          document.documentElement.scrollTop > 130) {
        if (!this.appBarScrollDown) this.appBarScrollDown = true
      } else {
        if (this.appBarScrollDown) this.appBarScrollDown = false
      }
    };
  },
  methods: {
    changeLanguage(lang) {
      this.$vuetify.lang.current = lang
      this.drawerLanguageDropdown = false
      this.drawer = false
    }
  },
  created() {
    this.$vuetify.lang.locales.en.navigation = {
      home: "Home",
      aboutUs: "About Us",
      ourOffers: "Investment",
      opportunities: "Opportunities & Solutions",
      approach: "Approach",
      insights: "Insights",
      vcc: "VCC",
      ukRealEstate: "UK Real Estate",
      contactUs: "Contact Us",
      language: "Language",
    }
    this.$vuetify.lang.locales.zhHans.navigation = {
      home: "主页",
      aboutUs: "关于我们",
      ourOffers: "服务领域",
      opportunities: "机会和策略",
      approach: "投资理念",
      insights: "市场洞察",
      vcc: "可变动资本公司",
      ukRealEstate: "英国地产",
      contactUs: "联系我们",
      language: "语言",
    }
  }
}
</script>

<style lang="less" scoped>
.lang-zhHans .v-btn {
  font-weight: 600;
}

.v-app-bar {
  transition: all 0.8s;

  .v-btn {
    color: white;
  }

  .v-app-bar__nav-icon {
    display: none;
  }
}

.logo {
  span {
    font-weight: 500;
    line-height: 30px;
    font-size: 28px;
    letter-spacing: -.02em;
    color: white;
  }

  .full-name {
    font-size: 15px;
    line-height: 22px;
    padding-left: 8px;
    border-left: 1px solid white;
  }
}

.scroll-down {
  .logo {
    span {
      color: black;
    }

    .full-name {
      border-left: 1px solid black;
    }
  }

  .v-btn {
    color: black;
  }
}

@media (max-width: 1080px) {

  .v-app-bar {
    .logo {
      margin: 0 auto;

      span {
        margin-left: -35px;
      }
    }

    .full-name {
      display: none;
    }

    .v-btn {
      display: none;
    }

    .spacer {
      display: none;
    }

    .v-app-bar__nav-icon {
      display: inline-block;

      span {
        i {
          color: white !important;
        }
      }
    }

  }

  .scroll-down {
    .v-app-bar__nav-icon {
      span {
        i {
          color: black !important;
        }
      }
    }
  }

}

</style>